import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/firebase-storage";
//TODO: I updated these imports to avoid getting the stuff we dont need from firebase
//but im still getting the error in console about the development build of firebase

//actual config for Emmanuel's account
var firebaseConfig = {
  apiKey: "AIzaSyClhVyn-MAVOLu1jzrBSDdM_NQ88SlsN0U",
  authDomain: "kinashe-c6bc6.firebaseapp.com",
  databaseURL: "https://kinashe-c6bc6.firebaseio.com",
  projectId: "kinashe-c6bc6",
  storageBucket: "kinashe-c6bc6.appspot.com",
  messagingSenderId: "455837118847",
  appId: "1:455837118847:web:ee34203fd2b02939932a4a",
  measurementId: "G-SJFJ3PQ90P"
};
firebase.initializeApp(firebaseConfig);
export default firebase;
