import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

class ContactAdmin extends Component {
  render() {
    return (
      <Typography component="h1" variant="h5">
        This page will have admin contact info
      </Typography>
    );
  }
}

export default ContactAdmin;
